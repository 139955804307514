/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

//import* as APITypes from "../API";
/*Query<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
*/

exports.pinpointRadix = /* GraphQL */ `query PinpointRadix($somearg: String) {
  pinpointRadix(somearg: $somearg)
}
` ; /* GeneratedQuery<
  APITypes.PinpointRadixQueryVariables,
  APITypes.PinpointRadixQuery
 ; */ 
exports.getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    email
    createdAt
    updatedAt
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    watchProgresses {
      items {
        id
        owner
        seconds
        contentId
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications
    appSettingsPreferences
    stats
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery ; */ 
exports.listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      createdAt
      updatedAt
      watchList {
        nextToken
        __typename
      }
      watchProgresses {
        nextToken
        __typename
      }
      notifications
      appSettingsPreferences
      stats
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery ; */ 
exports.getGame = /* GraphQL */ `query GetGame($id: ID!) {
  getGame(id: $id) {
    id
    name
    description
    homeTeamId
    homeTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    awayTeamId
    awayTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    gameStartDateTime
    gameEndDateTime
    status
    tags
    seasonYear
    seasonType
    week
    fullWeek
    GB_LastScanStart
    GB_LastScanEnd
    quicksandStartIntervalSeconds
    quicksandEndIntervalSeconds
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    nlseAssetHouseCode
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    programGamesId
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.GetGameQueryVariables, APITypes.GetGameQuery ; */ 
exports.listGames = /* GraphQL */ `query ListGames(
  $id: ID
  $filter: ModelGameFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGames(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.ListGamesQueryVariables, APITypes.ListGamesQuery ; */ 
exports.gameBySeasonYearLastscanend = /* GraphQL */ `query GameBySeasonYearLastscanend(
  $seasonYear: SeasonYear!
  $GB_LastScanEnd: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGameFilterInput
  $limit: Int
  $nextToken: String
) {
  gameBySeasonYearLastscanend(
    seasonYear: $seasonYear
    GB_LastScanEnd: $GB_LastScanEnd
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GameBySeasonYearLastscanendQueryVariables,
  APITypes.GameBySeasonYearLastscanendQuery
 ; */ 
exports.gameByFullWeekLastscanend = /* GraphQL */ `query GameByFullWeekLastscanend(
  $fullWeek: ID!
  $GB_LastScanEnd: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGameFilterInput
  $limit: Int
  $nextToken: String
) {
  gameByFullWeekLastscanend(
    fullWeek: $fullWeek
    GB_LastScanEnd: $GB_LastScanEnd
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GameByFullWeekLastscanendQueryVariables,
  APITypes.GameByFullWeekLastscanendQuery
 ; */ 
exports.searchGames = /* GraphQL */ `query SearchGames(
  $filter: SearchableGameFilterInput
  $sort: [SearchableGameSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableGameAggregationInput]
) {
  searchGames(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchGamesQueryVariables,
  APITypes.SearchGamesQuery
 ; */ 
exports.getProgram = /* GraphQL */ `query GetProgram($id: ID!) {
  getProgram(id: $id) {
    id
    name
    description
    tvRating
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    games {
      items {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      nextToken
      __typename
    }
    streamingLiveScheduleEvents {
      items {
        id
        title
        description
        imageURL
        DateTime
        filterDateTime
        gameId
        contentId
        leagueId
        tags
        playbackURL
        createdBy
        updatedBy
        version
        imageFilename
        blurHash
        createdAt
        updatedAt
        programStreamingLiveScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
    createdBy
    updatedBy
    imageFilename
    blurHash
    seasons
    publishAtDate
    expirationAtDate
    onlyShowEpisodesInAProgramAsOneItem
    availabilityStatus
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetProgramQueryVariables,
  APITypes.GetProgramQuery
 ; */ 
exports.listPrograms = /* GraphQL */ `query ListPrograms(
  $filter: ModelProgramFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListProgramsQueryVariables,
  APITypes.ListProgramsQuery
 ; */ 
exports.getContent = /* GraphQL */ `query GetContent($id: ID!) {
  getContent(id: $id) {
    id
    title
    description
    note
    video
    gameId
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    vodEntryId
    vodEntry {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    tvRating
    contentType
    applicationType
    nlseAssetHouseCode
    episode
    episodeId
    episodeOrder
    episodeSeason
    isSupplementalContent
    supplementalContentOrder
    mainContentId
    publishAtDate
    expirationAtDate
    availabilityStatus
    contentLength
    seasonYear
    seasonType
    location
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    tags
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    reservationArn
    reservationAuthorized
    reservationName
    reservationPlaybackURL
    reservationTags
    reservationType
    oldReservationArn
    liveToVodPlaybackURL
    errorStatus
    errorMessageForVideoUpload
    sortByDate
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetContentQueryVariables,
  APITypes.GetContentQuery
 ; */ 
exports.listContents = /* GraphQL */ `query ListContents(
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListContentsQueryVariables,
  APITypes.ListContentsQuery
 ; */ 
exports.mainContentIdForSupplementalContent = /* GraphQL */ `query MainContentIdForSupplementalContent(
  $mainContentId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  mainContentIdForSupplementalContent(
    mainContentId: $mainContentId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.MainContentIdForSupplementalContentQueryVariables,
  APITypes.MainContentIdForSupplementalContentQuery
 ; */ 
exports.searchContents = /* GraphQL */ `query SearchContents(
  $filter: SearchableContentFilterInput
  $sort: [SearchableContentSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableContentAggregationInput]
) {
  searchContents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchContentsQueryVariables,
  APITypes.SearchContentsQuery
 ; */ 
exports.getStreamingHomePage = /* GraphQL */ `query GetStreamingHomePage($id: ID!) {
  getStreamingHomePage(id: $id) {
    id
    categoryTitle
    categoryOrder
    contentIDs
    programIDs
    leagueIDs
    sport
    sportTags
    sportTagsBooleanOp
    contentLayoutStyle
    banner1Id
    banner1 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    banner2Id
    banner2 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    banner3Id
    banner3 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    publishAtDate
    expirationAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingHomePageQueryVariables,
  APITypes.GetStreamingHomePageQuery
 ; */ 
exports.listStreamingHomePages = /* GraphQL */ `query ListStreamingHomePages(
  $filter: ModelStreamingHomePageFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingHomePages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryTitle
      categoryOrder
      contentIDs
      programIDs
      leagueIDs
      sport
      sportTags
      sportTagsBooleanOp
      contentLayoutStyle
      banner1Id
      banner1 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      banner2Id
      banner2 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      banner3Id
      banner3 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      publishAtDate
      expirationAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingHomePagesQueryVariables,
  APITypes.ListStreamingHomePagesQuery
 ; */ 
exports.getStreamingSportsAndLeaguesPage = /* GraphQL */ `query GetStreamingSportsAndLeaguesPage($id: ID!) {
  getStreamingSportsAndLeaguesPage(id: $id) {
    id
    categoryTitle
    categoryOrder
    sport
    sportTags
    leagues {
      items {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingSportsAndLeaguesPageQueryVariables,
  APITypes.GetStreamingSportsAndLeaguesPageQuery
 ; */ 
exports.listStreamingSportsAndLeaguesPages = /* GraphQL */ `query ListStreamingSportsAndLeaguesPages(
  $filter: ModelStreamingSportsAndLeaguesPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingSportsAndLeaguesPages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryTitle
      categoryOrder
      sport
      sportTags
      leagues {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingSportsAndLeaguesPagesQueryVariables,
  APITypes.ListStreamingSportsAndLeaguesPagesQuery
 ; */ 
exports.getLiveStreamChannel = /* GraphQL */ `query GetLiveStreamChannel($id: ID!) {
  getLiveStreamChannel(id: $id) {
    id
    channel_name
    cdn_url
    endpoint_url
    is_active
    is_deleted
    stats
    searchSink
    inputType
    stackStatus
    stackStatusReason
    stackSubjectToPeriodicUpdates
    stackParameters
    stackOutputs
    stackArn
    stackReady
    stackOutputCloudFrontCmafEndpoint
    stackOutputCloudFrontDashEndpoint
    stackOutputCloudFrontHlsEndpoint
    stackOutputDemoBucketConsole
    stackOutputDemoPlayer
    stackOutputLogsBucketConsole
    stackOutputMediaLiveChannelConsole
    stackOutputMediaLiveChannelId
    stackOutputMediaLiveMetrics
    stackOutputMediaLivePrimaryEndpoint
    stackOutputMediaLiveSecondaryEndpoint
    stackOutputMediaPackageMetrics
    stackOutputAnonymousMetricUUID
    stackOutputAppRegistryConsole
    scheduledStartTime
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetLiveStreamChannelQueryVariables,
  APITypes.GetLiveStreamChannelQuery
 ; */ 
exports.listLiveStreamChannels = /* GraphQL */ `query ListLiveStreamChannels(
  $id: ID
  $filter: ModelLiveStreamChannelFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLiveStreamChannels(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      channel_name
      cdn_url
      endpoint_url
      is_active
      is_deleted
      stats
      searchSink
      inputType
      stackStatus
      stackStatusReason
      stackSubjectToPeriodicUpdates
      stackParameters
      stackOutputs
      stackArn
      stackReady
      stackOutputCloudFrontCmafEndpoint
      stackOutputCloudFrontDashEndpoint
      stackOutputCloudFrontHlsEndpoint
      stackOutputDemoBucketConsole
      stackOutputDemoPlayer
      stackOutputLogsBucketConsole
      stackOutputMediaLiveChannelConsole
      stackOutputMediaLiveChannelId
      stackOutputMediaLiveMetrics
      stackOutputMediaLivePrimaryEndpoint
      stackOutputMediaLiveSecondaryEndpoint
      stackOutputMediaPackageMetrics
      stackOutputAnonymousMetricUUID
      stackOutputAppRegistryConsole
      scheduledStartTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListLiveStreamChannelsQueryVariables,
  APITypes.ListLiveStreamChannelsQuery
 ; */ 
exports.searchLiveStreamChannels = /* GraphQL */ `query SearchLiveStreamChannels(
  $filter: SearchableLiveStreamChannelFilterInput
  $sort: [SearchableLiveStreamChannelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLiveStreamChannelAggregationInput]
) {
  searchLiveStreamChannels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      channel_name
      cdn_url
      endpoint_url
      is_active
      is_deleted
      stats
      searchSink
      inputType
      stackStatus
      stackStatusReason
      stackSubjectToPeriodicUpdates
      stackParameters
      stackOutputs
      stackArn
      stackReady
      stackOutputCloudFrontCmafEndpoint
      stackOutputCloudFrontDashEndpoint
      stackOutputCloudFrontHlsEndpoint
      stackOutputDemoBucketConsole
      stackOutputDemoPlayer
      stackOutputLogsBucketConsole
      stackOutputMediaLiveChannelConsole
      stackOutputMediaLiveChannelId
      stackOutputMediaLiveMetrics
      stackOutputMediaLivePrimaryEndpoint
      stackOutputMediaLiveSecondaryEndpoint
      stackOutputMediaPackageMetrics
      stackOutputAnonymousMetricUUID
      stackOutputAppRegistryConsole
      scheduledStartTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchLiveStreamChannelsQueryVariables,
  APITypes.SearchLiveStreamChannelsQuery
 ; */ 
exports.getVodEntry = /* GraphQL */ `query GetVodEntry($id: ID!) {
  getVodEntry(id: $id) {
    id
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    stage
    publishedAt
    createdBy
    updatedBy
    errorStatus
    errorDescripton
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetVodEntryQueryVariables,
  APITypes.GetVodEntryQuery
 ; */ 
exports.listVodEntries = /* GraphQL */ `query ListVodEntries(
  $id: ID
  $filter: ModelVodEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVodEntries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListVodEntriesQueryVariables,
  APITypes.ListVodEntriesQuery
 ; */ 
exports.searchVodEntries = /* GraphQL */ `query SearchVodEntries(
  $filter: SearchableVodEntryFilterInput
  $sort: [SearchableVodEntrySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableVodEntryAggregationInput]
) {
  searchVodEntries(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchVodEntriesQueryVariables,
  APITypes.SearchVodEntriesQuery
 ; */ 
exports.getVodDeleteInProgress = /* GraphQL */ `query GetVodDeleteInProgress($id: ID!) {
  getVodDeleteInProgress(id: $id) {
    id
    destinationBucketContinuationToken
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    note
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetVodDeleteInProgressQueryVariables,
  APITypes.GetVodDeleteInProgressQuery
 ; */ 
exports.listVodDeleteInProgresses = /* GraphQL */ `query ListVodDeleteInProgresses(
  $id: ID
  $filter: ModelVodDeleteInProgressFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVodDeleteInProgresses(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      destinationBucketContinuationToken
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      note
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListVodDeleteInProgressesQueryVariables,
  APITypes.ListVodDeleteInProgressesQuery
 ; */ 
exports.getWatchProgress = /* GraphQL */ `query GetWatchProgress($id: ID!) {
  getWatchProgress(id: $id) {
    id
    owner
    seconds
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    count
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetWatchProgressQueryVariables,
  APITypes.GetWatchProgressQuery
 ; */ 
exports.listWatchProgresses = /* GraphQL */ `query ListWatchProgresses(
  $filter: ModelWatchProgressFilterInput
  $limit: Int
  $nextToken: String
) {
  listWatchProgresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      seconds
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListWatchProgressesQueryVariables,
  APITypes.ListWatchProgressesQuery
 ; */ 
exports.searchWatchProgresses = /* GraphQL */ `query SearchWatchProgresses(
  $filter: SearchableWatchProgressFilterInput
  $sort: [SearchableWatchProgressSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableWatchProgressAggregationInput]
) {
  searchWatchProgresses(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      owner
      seconds
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchWatchProgressesQueryVariables,
  APITypes.SearchWatchProgressesQuery
 ; */ 
exports.getFrontendTelevisionProviderInformation = /* GraphQL */ `query GetFrontendTelevisionProviderInformation($id: ID!) {
  getFrontendTelevisionProviderInformation(id: $id) {
    id
    provider
    description
    conduits
    providerType
    providerURL
    imageURL
    alt
    street
    city
    zipcode
    popular
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetFrontendTelevisionProviderInformationQueryVariables,
  APITypes.GetFrontendTelevisionProviderInformationQuery
 ; */ 
exports.listFrontendTelevisionProviderInformations = /* GraphQL */ `query ListFrontendTelevisionProviderInformations(
  $filter: ModelFrontendTelevisionProviderInformationFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrontendTelevisionProviderInformations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListFrontendTelevisionProviderInformationsQueryVariables,
  APITypes.ListFrontendTelevisionProviderInformationsQuery
 ; */ 
exports.getFrontendLeague = /* GraphQL */ `query GetFrontendLeague($id: ID!) {
  getFrontendLeague(id: $id) {
    id
    imageURL
    sport
    sportTypeTags
    region
    description
    title
    imageFilename
    blurHash
    createdBy
    updatedBy
    primaryColor
    secondaryColor
    textColorForPrimaryColor
    textColorForSecondaryColor
    backgroundColor
    textColorForBackgroundColor
    abbreviation
    fullName
    buyTicketsUrl
    publishBuyTicketsAtDate
    expirationBuyTicketsAtDate
    logoImageId
    logoImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    bannerImageId
    bannerImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage1Id
    extraImage1 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage2Id
    extraImage2 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage3Id
    extraImage3 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage4Id
    extraImage4 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage5Id
    extraImage5 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    yearFounded
    yearInauguralSeason
    numberOfTeams
    leagueInfoUrl
    leaguePromoVideoContentId
    currentSeason
    createdAt
    updatedAt
    streamingSportsAndLeaguesPageLeaguesId
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetFrontendLeagueQueryVariables,
  APITypes.GetFrontendLeagueQuery
 ; */ 
exports.listFrontendLeagues = /* GraphQL */ `query ListFrontendLeagues(
  $filter: ModelFrontendLeagueFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrontendLeagues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListFrontendLeaguesQueryVariables,
  APITypes.ListFrontendLeaguesQuery
 ; */ 
exports.getFrontendAdvertiser = /* GraphQL */ `query GetFrontendAdvertiser($id: ID!) {
  getFrontendAdvertiser(id: $id) {
    id
    image
    companyName
    url
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetFrontendAdvertiserQueryVariables,
  APITypes.GetFrontendAdvertiserQuery
 ; */ 
exports.listFrontendAdvertisers = /* GraphQL */ `query ListFrontendAdvertisers(
  $filter: ModelFrontendAdvertiserFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrontendAdvertisers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      image
      companyName
      url
      isPrimarySponsor
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListFrontendAdvertisersQueryVariables,
  APITypes.ListFrontendAdvertisersQuery
 ; */ 
exports.getFrontendTermsOfUse = /* GraphQL */ `query GetFrontendTermsOfUse($id: ID!) {
  getFrontendTermsOfUse(id: $id) {
    id
    lastUpdated
    termsOfUse
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetFrontendTermsOfUseQueryVariables,
  APITypes.GetFrontendTermsOfUseQuery
 ; */ 
exports.listFrontendTermsOfUses = /* GraphQL */ `query ListFrontendTermsOfUses(
  $filter: ModelFrontendTermsOfUseFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrontendTermsOfUses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      lastUpdated
      termsOfUse
      applicationType
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListFrontendTermsOfUsesQueryVariables,
  APITypes.ListFrontendTermsOfUsesQuery
 ; */ 
exports.getFrontendPrivacyPolicy = /* GraphQL */ `query GetFrontendPrivacyPolicy($id: ID!) {
  getFrontendPrivacyPolicy(id: $id) {
    id
    lastUpdated
    privacyPolicy
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetFrontendPrivacyPolicyQueryVariables,
  APITypes.GetFrontendPrivacyPolicyQuery
 ; */ 
exports.listFrontendPrivacyPolicies = /* GraphQL */ `query ListFrontendPrivacyPolicies(
  $filter: ModelFrontendPrivacyPolicyFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrontendPrivacyPolicies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      lastUpdated
      privacyPolicy
      applicationType
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListFrontendPrivacyPoliciesQueryVariables,
  APITypes.ListFrontendPrivacyPoliciesQuery
 ; */ 
exports.getPrimaryDigitalInnovation = /* GraphQL */ `query GetPrimaryDigitalInnovation($id: ID!) {
  getPrimaryDigitalInnovation(id: $id) {
    id
    title
    description
    imageURL
    order
    extraURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryDigitalInnovationQueryVariables,
  APITypes.GetPrimaryDigitalInnovationQuery
 ; */ 
exports.listPrimaryDigitalInnovations = /* GraphQL */ `query ListPrimaryDigitalInnovations(
  $filter: ModelPrimaryDigitalInnovationFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryDigitalInnovations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      imageURL
      order
      extraURL
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryDigitalInnovationsQueryVariables,
  APITypes.ListPrimaryDigitalInnovationsQuery
 ; */ 
exports.getPrimaryNewsTopic = /* GraphQL */ `query GetPrimaryNewsTopic($id: ID!) {
  getPrimaryNewsTopic(id: $id) {
    id
    title
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryNewsTopicQueryVariables,
  APITypes.GetPrimaryNewsTopicQuery
 ; */ 
exports.listPrimaryNewsTopics = /* GraphQL */ `query ListPrimaryNewsTopics(
  $filter: ModelPrimaryNewsTopicFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryNewsTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      searchFilterKeyWords
      order
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryNewsTopicsQueryVariables,
  APITypes.ListPrimaryNewsTopicsQuery
 ; */ 
exports.getPrimarySlider = /* GraphQL */ `query GetPrimarySlider($id: ID!) {
  getPrimarySlider(id: $id) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    videoURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimarySliderQueryVariables,
  APITypes.GetPrimarySliderQuery
 ; */ 
exports.listPrimarySliders = /* GraphQL */ `query ListPrimarySliders(
  $filter: ModelPrimarySliderFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimarySliders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      descriptionTop
      descriptionBottom
      imageURL
      order
      providerId
      provider {
        id
        provider
        description
        conduits
        providerType
        providerURL
        imageURL
        alt
        street
        city
        zipcode
        popular
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      category
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      extraURL
      videoURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      expirationDate
      publishedAtDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimarySlidersQueryVariables,
  APITypes.ListPrimarySlidersQuery
 ; */ 
exports.getPrimaryAdvertiserSponsor = /* GraphQL */ `query GetPrimaryAdvertiserSponsor($id: ID!) {
  getPrimaryAdvertiserSponsor(id: $id) {
    id
    name
    imageURL
    advertiserURL
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryAdvertiserSponsorQueryVariables,
  APITypes.GetPrimaryAdvertiserSponsorQuery
 ; */ 
exports.listPrimaryAdvertiserSponsors = /* GraphQL */ `query ListPrimaryAdvertiserSponsors(
  $filter: ModelPrimaryAdvertiserSponsorFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryAdvertiserSponsors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      imageURL
      advertiserURL
      isPrimarySponsor
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryAdvertiserSponsorsQueryVariables,
  APITypes.ListPrimaryAdvertiserSponsorsQuery
 ; */ 
exports.getPrimaryContactUs = /* GraphQL */ `query GetPrimaryContactUs($id: ID!) {
  getPrimaryContactUs(id: $id) {
    id
    firstName
    lastName
    userEmailAddress
    subject
    message
    helpServiceEmail
    divisionType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryContactUsQueryVariables,
  APITypes.GetPrimaryContactUsQuery
 ; */ 
exports.listPrimaryContactuses = /* GraphQL */ `query ListPrimaryContactuses(
  $filter: ModelPrimaryContactUsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryContactuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      userEmailAddress
      subject
      message
      helpServiceEmail
      divisionType
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryContactusesQueryVariables,
  APITypes.ListPrimaryContactusesQuery
 ; */ 
exports.getPrimaryUserMessaging = /* GraphQL */ `query GetPrimaryUserMessaging($id: ID!) {
  getPrimaryUserMessaging(id: $id) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryUserMessagingQueryVariables,
  APITypes.GetPrimaryUserMessagingQuery
 ; */ 
exports.listPrimaryUserMessagings = /* GraphQL */ `query ListPrimaryUserMessagings(
  $filter: ModelPrimaryUserMessagingFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryUserMessagings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      pageToDisplayMessage
      expirationDate
      publishedAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryUserMessagingsQueryVariables,
  APITypes.ListPrimaryUserMessagingsQuery
 ; */ 
exports.listPrimaryUserMessagingByPageAndDate = /* GraphQL */ `query ListPrimaryUserMessagingByPageAndDate(
  $pageToDisplayMessage: PrimaryPageEnum!
  $publishedAtDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrimaryUserMessagingFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryUserMessagingByPageAndDate(
    pageToDisplayMessage: $pageToDisplayMessage
    publishedAtDate: $publishedAtDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      pageToDisplayMessage
      expirationDate
      publishedAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryUserMessagingByPageAndDateQueryVariables,
  APITypes.ListPrimaryUserMessagingByPageAndDateQuery
 ; */ 
exports.getStreamingUserMessaging = /* GraphQL */ `query GetStreamingUserMessaging($id: ID!) {
  getStreamingUserMessaging(id: $id) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingUserMessagingQueryVariables,
  APITypes.GetStreamingUserMessagingQuery
 ; */ 
exports.listStreamingUserMessagings = /* GraphQL */ `query ListStreamingUserMessagings(
  $filter: ModelStreamingUserMessagingFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingUserMessagings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      pageToDisplayMessage
      expirationDate
      publishedAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingUserMessagingsQueryVariables,
  APITypes.ListStreamingUserMessagingsQuery
 ; */ 
exports.listStreamingUserMessagingByPageAndDate = /* GraphQL */ `query ListStreamingUserMessagingByPageAndDate(
  $pageToDisplayMessage: StreamingPageEnum!
  $publishedAtDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStreamingUserMessagingFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingUserMessagingByPageAndDate(
    pageToDisplayMessage: $pageToDisplayMessage
    publishedAtDate: $publishedAtDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      pageToDisplayMessage
      expirationDate
      publishedAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingUserMessagingByPageAndDateQueryVariables,
  APITypes.ListStreamingUserMessagingByPageAndDateQuery
 ; */ 
exports.getPrimaryCareerJobPost = /* GraphQL */ `query GetPrimaryCareerJobPost($id: ID!) {
  getPrimaryCareerJobPost(id: $id) {
    id
    jobTitle
    description
    jobPostingURL
    location
    category
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetPrimaryCareerJobPostQueryVariables,
  APITypes.GetPrimaryCareerJobPostQuery
 ; */ 
exports.listPrimaryCareerJobPosts = /* GraphQL */ `query ListPrimaryCareerJobPosts(
  $filter: ModelPrimaryCareerJobPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrimaryCareerJobPosts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      jobTitle
      description
      jobPostingURL
      location
      category
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListPrimaryCareerJobPostsQueryVariables,
  APITypes.ListPrimaryCareerJobPostsQuery
 ; */ 
exports.getStreamingSlider = /* GraphQL */ `query GetStreamingSlider($id: ID!) {
  getStreamingSlider(id: $id) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    contentStatusType
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingSliderQueryVariables,
  APITypes.GetStreamingSliderQuery
 ; */ 
exports.listStreamingSliders = /* GraphQL */ `query ListStreamingSliders(
  $filter: ModelStreamingSliderFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingSliders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      descriptionTop
      descriptionBottom
      imageURL
      order
      providerId
      provider {
        id
        provider
        description
        conduits
        providerType
        providerURL
        imageURL
        alt
        street
        city
        zipcode
        popular
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      category
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      extraURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      expirationDate
      publishedAtDate
      contentStatusType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingSlidersQueryVariables,
  APITypes.ListStreamingSlidersQuery
 ; */ 
exports.getStreamingSearchTopic = /* GraphQL */ `query GetStreamingSearchTopic($id: ID!) {
  getStreamingSearchTopic(id: $id) {
    id
    title
    description
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingSearchTopicQueryVariables,
  APITypes.GetStreamingSearchTopicQuery
 ; */ 
exports.listStreamingSearchTopics = /* GraphQL */ `query ListStreamingSearchTopics(
  $filter: ModelStreamingSearchTopicFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingSearchTopics(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      searchFilterKeyWords
      order
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingSearchTopicsQueryVariables,
  APITypes.ListStreamingSearchTopicsQuery
 ; */ 
exports.getStreamingContactCustomerService = /* GraphQL */ `query GetStreamingContactCustomerService($id: ID!) {
  getStreamingContactCustomerService(id: $id) {
    id
    username
    firstName
    lastName
    userContactEmail
    subject
    body
    customerServiceEmail
    helpTopic
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingContactCustomerServiceQueryVariables,
  APITypes.GetStreamingContactCustomerServiceQuery
 ; */ 
exports.listStreamingContactCustomerServices = /* GraphQL */ `query ListStreamingContactCustomerServices(
  $filter: ModelStreamingContactCustomerServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingContactCustomerServices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      firstName
      lastName
      userContactEmail
      subject
      body
      customerServiceEmail
      helpTopic
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingContactCustomerServicesQueryVariables,
  APITypes.ListStreamingContactCustomerServicesQuery
 ; */ 
exports.getStreamingHelpCenter = /* GraphQL */ `query GetStreamingHelpCenter($id: ID!) {
  getStreamingHelpCenter(id: $id) {
    id
    question
    answer
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingHelpCenterQueryVariables,
  APITypes.GetStreamingHelpCenterQuery
 ; */ 
exports.listStreamingHelpCenters = /* GraphQL */ `query ListStreamingHelpCenters(
  $filter: ModelStreamingHelpCenterFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingHelpCenters(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      question
      answer
      order
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingHelpCentersQueryVariables,
  APITypes.ListStreamingHelpCentersQuery
 ; */ 
exports.getStreamingLiveScheduleEvent = /* GraphQL */ `query GetStreamingLiveScheduleEvent($id: ID!) {
  getStreamingLiveScheduleEvent(id: $id) {
    id
    title
    description
    imageURL
    DateTime
    filterDateTime
    gameId
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    playbackURL
    createdBy
    updatedBy
    version
    imageFilename
    blurHash
    createdAt
    updatedAt
    programStreamingLiveScheduleEventsId
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingLiveScheduleEventQueryVariables,
  APITypes.GetStreamingLiveScheduleEventQuery
 ; */ 
exports.listStreamingLiveScheduleEvents = /* GraphQL */ `query ListStreamingLiveScheduleEvents(
  $filter: ModelStreamingLiveScheduleEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingLiveScheduleEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      imageURL
      DateTime
      filterDateTime
      gameId
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      playbackURL
      createdBy
      updatedBy
      version
      imageFilename
      blurHash
      createdAt
      updatedAt
      programStreamingLiveScheduleEventsId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingLiveScheduleEventsQueryVariables,
  APITypes.ListStreamingLiveScheduleEventsQuery
 ; */ 
exports.streamingLiveScheduleEventsByContentId = /* GraphQL */ `query StreamingLiveScheduleEventsByContentId(
  $contentId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStreamingLiveScheduleEventFilterInput
  $limit: Int
  $nextToken: String
) {
  streamingLiveScheduleEventsByContentId(
    contentId: $contentId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      imageURL
      DateTime
      filterDateTime
      gameId
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      playbackURL
      createdBy
      updatedBy
      version
      imageFilename
      blurHash
      createdAt
      updatedAt
      programStreamingLiveScheduleEventsId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.StreamingLiveScheduleEventsByContentIdQueryVariables,
  APITypes.StreamingLiveScheduleEventsByContentIdQuery
 ; */ 
exports.streamingLiveScheduleEventsByLeagueId = /* GraphQL */ `query StreamingLiveScheduleEventsByLeagueId(
  $leagueId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStreamingLiveScheduleEventFilterInput
  $limit: Int
  $nextToken: String
) {
  streamingLiveScheduleEventsByLeagueId(
    leagueId: $leagueId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      imageURL
      DateTime
      filterDateTime
      gameId
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      playbackURL
      createdBy
      updatedBy
      version
      imageFilename
      blurHash
      createdAt
      updatedAt
      programStreamingLiveScheduleEventsId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.StreamingLiveScheduleEventsByLeagueIdQueryVariables,
  APITypes.StreamingLiveScheduleEventsByLeagueIdQuery
 ; */ 
exports.streamingLiveScheduleEventsByDate = /* GraphQL */ `query StreamingLiveScheduleEventsByDate(
  $version: Int!
  $DateTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStreamingLiveScheduleEventFilterInput
  $limit: Int
  $nextToken: String
) {
  streamingLiveScheduleEventsByDate(
    version: $version
    DateTime: $DateTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      imageURL
      DateTime
      filterDateTime
      gameId
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      playbackURL
      createdBy
      updatedBy
      version
      imageFilename
      blurHash
      createdAt
      updatedAt
      programStreamingLiveScheduleEventsId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.StreamingLiveScheduleEventsByDateQueryVariables,
  APITypes.StreamingLiveScheduleEventsByDateQuery
 ; */ 
exports.getStreamingAdvertiserSponsor = /* GraphQL */ `query GetStreamingAdvertiserSponsor($id: ID!) {
  getStreamingAdvertiserSponsor(id: $id) {
    id
    companyName
    companyImageURL
    displayTitle
    displayDescription
    displayImageURL
    advertiserURL
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingAdvertiserSponsorQueryVariables,
  APITypes.GetStreamingAdvertiserSponsorQuery
 ; */ 
exports.listStreamingAdvertiserSponsors = /* GraphQL */ `query ListStreamingAdvertiserSponsors(
  $filter: ModelStreamingAdvertiserSponsorFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingAdvertiserSponsors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyName
      companyImageURL
      displayTitle
      displayDescription
      displayImageURL
      advertiserURL
      order
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingAdvertiserSponsorsQueryVariables,
  APITypes.ListStreamingAdvertiserSponsorsQuery
 ; */ 
exports.getNewsArticle = /* GraphQL */ `query GetNewsArticle($id: ID!) {
  getNewsArticle(id: $id) {
    id
    title
    imageURL
    subtitle
    text
    summary
    dateTime
    releaseURL
    shortTitle
    tags
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    sportTypeTags
    relatedArticles {
      items {
        id
        title
        imageURL
        subtitle
        text
        summary
        dateTime
        releaseURL
        shortTitle
        tags
        leagueId
        sport
        sportTypeTags
        stage
        publishedAt
        createdBy
        updatedBy
        frontendNewsMedium
        extraURL
        pinned
        contentId
        applicationType
        authorId
        photoCredit
        searchSink
        imageFilename
        blurHash
        createdAt
        updatedAt
        newsArticleRelatedArticlesId
        __typename
      }
      nextToken
      __typename
    }
    stage
    publishedAt
    createdBy
    updatedBy
    frontendNewsMedium
    extraURL
    pinned
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    applicationType
    authorId
    author {
      id
      fullName
      designation
      imageFilename
      imageURL
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    photoCredit
    searchSink
    imageFilename
    blurHash
    createdAt
    updatedAt
    newsArticleRelatedArticlesId
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetNewsArticleQueryVariables,
  APITypes.GetNewsArticleQuery
 ; */ 
exports.listNewsArticles = /* GraphQL */ `query ListNewsArticles(
  $filter: ModelNewsArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  listNewsArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      imageURL
      subtitle
      text
      summary
      dateTime
      releaseURL
      shortTitle
      tags
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTypeTags
      relatedArticles {
        nextToken
        __typename
      }
      stage
      publishedAt
      createdBy
      updatedBy
      frontendNewsMedium
      extraURL
      pinned
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      applicationType
      authorId
      author {
        id
        fullName
        designation
        imageFilename
        imageURL
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      photoCredit
      searchSink
      imageFilename
      blurHash
      createdAt
      updatedAt
      newsArticleRelatedArticlesId
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListNewsArticlesQueryVariables,
  APITypes.ListNewsArticlesQuery
 ; */ 
exports.searchNewsArticles = /* GraphQL */ `query SearchNewsArticles(
  $filter: SearchableNewsArticleFilterInput
  $sort: [SearchableNewsArticleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableNewsArticleAggregationInput]
) {
  searchNewsArticles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      imageURL
      subtitle
      text
      summary
      dateTime
      releaseURL
      shortTitle
      tags
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTypeTags
      relatedArticles {
        nextToken
        __typename
      }
      stage
      publishedAt
      createdBy
      updatedBy
      frontendNewsMedium
      extraURL
      pinned
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      applicationType
      authorId
      author {
        id
        fullName
        designation
        imageFilename
        imageURL
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      photoCredit
      searchSink
      imageFilename
      blurHash
      createdAt
      updatedAt
      newsArticleRelatedArticlesId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchNewsArticlesQueryVariables,
  APITypes.SearchNewsArticlesQuery
 ; */ 
exports.getAuthor = /* GraphQL */ `query GetAuthor($id: ID!) {
  getAuthor(id: $id) {
    id
    fullName
    designation
    imageFilename
    imageURL
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.GetAuthorQueryVariables, APITypes.GetAuthorQuery ; */ 
exports.listAuthors = /* GraphQL */ `query ListAuthors(
  $filter: ModelAuthorFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fullName
      designation
      imageFilename
      imageURL
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListAuthorsQueryVariables,
  APITypes.ListAuthorsQuery
 ; */ 
exports.getTVScheduledEvent = /* GraphQL */ `query GetTVScheduledEvent($id: ID!) {
  getTVScheduledEvent(id: $id) {
    id
    title
    description
    dateTime
    filterDateTime
    createdBy
    updatedBy
    version
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetTVScheduledEventQueryVariables,
  APITypes.GetTVScheduledEventQuery
 ; */ 
exports.listTVScheduledEvents = /* GraphQL */ `query ListTVScheduledEvents(
  $filter: ModelTVScheduledEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listTVScheduledEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      dateTime
      filterDateTime
      createdBy
      updatedBy
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListTVScheduledEventsQueryVariables,
  APITypes.ListTVScheduledEventsQuery
 ; */ 
exports.tvScheduledEventsByDate = /* GraphQL */ `query TvScheduledEventsByDate(
  $version: Int!
  $dateTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTVScheduledEventFilterInput
  $limit: Int
  $nextToken: String
) {
  tvScheduledEventsByDate(
    version: $version
    dateTime: $dateTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      dateTime
      filterDateTime
      createdBy
      updatedBy
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.TvScheduledEventsByDateQueryVariables,
  APITypes.TvScheduledEventsByDateQuery
 ; */ 
exports.getSportsTeam = /* GraphQL */ `query GetSportsTeam($id: ID!) {
  getSportsTeam(id: $id) {
    id
    name
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    location
    imageURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetSportsTeamQueryVariables,
  APITypes.GetSportsTeamQuery
 ; */ 
exports.listSportsTeams = /* GraphQL */ `query ListSportsTeams(
  $filter: ModelSportsTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listSportsTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListSportsTeamsQueryVariables,
  APITypes.ListSportsTeamsQuery
 ; */ 
exports.sportsTeamByLeagueId = /* GraphQL */ `query SportsTeamByLeagueId(
  $leagueId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSportsTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  sportsTeamByLeagueId(
    leagueId: $leagueId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SportsTeamByLeagueIdQueryVariables,
  APITypes.SportsTeamByLeagueIdQuery
 ; */ 
exports.getLeadershipTeamMember = /* GraphQL */ `query GetLeadershipTeamMember($id: ID!) {
  getLeadershipTeamMember(id: $id) {
    id
    fullName
    jobTitle
    description
    imageURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetLeadershipTeamMemberQueryVariables,
  APITypes.GetLeadershipTeamMemberQuery
 ; */ 
exports.listLeadershipTeamMembers = /* GraphQL */ `query ListLeadershipTeamMembers(
  $filter: ModelLeadershipTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listLeadershipTeamMembers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullName
      jobTitle
      description
      imageURL
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListLeadershipTeamMembersQueryVariables,
  APITypes.ListLeadershipTeamMembersQuery
 ; */ 
exports.getLibraryImage = /* GraphQL */ `query GetLibraryImage($id: ID!) {
  getLibraryImage(id: $id) {
    id
    name
    altText
    imageFilename
    blurHash
    description
    tags
    createdBy
    updatedBy
    searchSink
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetLibraryImageQueryVariables,
  APITypes.GetLibraryImageQuery
 ; */ 
exports.listLibraryImages = /* GraphQL */ `query ListLibraryImages(
  $filter: ModelLibraryImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLibraryImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListLibraryImagesQueryVariables,
  APITypes.ListLibraryImagesQuery
 ; */ 
exports.searchLibraryImages = /* GraphQL */ `query SearchLibraryImages(
  $filter: SearchableLibraryImageFilterInput
  $sort: [SearchableLibraryImageSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLibraryImageAggregationInput]
) {
  searchLibraryImages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.SearchLibraryImagesQueryVariables,
  APITypes.SearchLibraryImagesQuery
 ; */ 
exports.getBanner = /* GraphQL */ `query GetBanner($id: ID!) {
  getBanner(id: $id) {
    id
    imageId
    image {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    title
    description
    layout
    tags
    buttonUrl
    buttonText
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<APITypes.GetBannerQueryVariables, APITypes.GetBannerQuery ; */ 
exports.listBanners = /* GraphQL */ `query ListBanners(
  $filter: ModelBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListBannersQueryVariables,
  APITypes.ListBannersQuery
 ; */ 
exports.getHomePageLeaguesRow = /* GraphQL */ `query GetHomePageLeaguesRow($id: ID!) {
  getHomePageLeaguesRow(id: $id) {
    id
    order
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetHomePageLeaguesRowQueryVariables,
  APITypes.GetHomePageLeaguesRowQuery
 ; */ 
exports.listHomePageLeaguesRows = /* GraphQL */ `query ListHomePageLeaguesRows(
  $filter: ModelHomePageLeaguesRowFilterInput
  $limit: Int
  $nextToken: String
) {
  listHomePageLeaguesRows(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListHomePageLeaguesRowsQueryVariables,
  APITypes.ListHomePageLeaguesRowsQuery
 ; */ 
exports.getExploreContent = /* GraphQL */ `query GetExploreContent($id: ID!) {
  getExploreContent(id: $id) {
    id
    order
    sport
    title
    thumbnailImageId
    thumbnailImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    sportDetailPageId
    sportDetailPage {
      id
      sport
      title
      description
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      contentRow1Id
      contentRow1 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow2Id
      contentRow2 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow3Id
      contentRow3 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow4Id
      contentRow4 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow5Id
      contentRow5 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow6Id
      contentRow6 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner1Id
      carouselBanner1 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner2Id
      carouselBanner2 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner3Id
      carouselBanner3 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetExploreContentQueryVariables,
  APITypes.GetExploreContentQuery
 ; */ 
exports.listExploreContents = /* GraphQL */ `query ListExploreContents(
  $filter: ModelExploreContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listExploreContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order
      sport
      title
      thumbnailImageId
      thumbnailImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      sportDetailPageId
      sportDetailPage {
        id
        sport
        title
        description
        bannerImageId
        contentRow1Id
        contentRow2Id
        contentRow3Id
        contentRow4Id
        contentRow5Id
        contentRow6Id
        carouselBanner1Id
        carouselBanner2Id
        carouselBanner3Id
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListExploreContentsQueryVariables,
  APITypes.ListExploreContentsQuery
 ; */ 
exports.getSportDetailPage = /* GraphQL */ `query GetSportDetailPage($id: ID!) {
  getSportDetailPage(id: $id) {
    id
    sport
    title
    description
    bannerImageId
    bannerImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    contentRow1Id
    contentRow1 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    contentRow2Id
    contentRow2 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    contentRow3Id
    contentRow3 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    contentRow4Id
    contentRow4 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    contentRow5Id
    contentRow5 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    contentRow6Id
    contentRow6 {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    carouselBanner1Id
    carouselBanner1 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    carouselBanner2Id
    carouselBanner2 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    carouselBanner3Id
    carouselBanner3 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetSportDetailPageQueryVariables,
  APITypes.GetSportDetailPageQuery
 ; */ 
exports.listSportDetailPages = /* GraphQL */ `query ListSportDetailPages(
  $filter: ModelSportDetailPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listSportDetailPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sport
      title
      description
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      contentRow1Id
      contentRow1 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow2Id
      contentRow2 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow3Id
      contentRow3 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow4Id
      contentRow4 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow5Id
      contentRow5 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      contentRow6Id
      contentRow6 {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner1Id
      carouselBanner1 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner2Id
      carouselBanner2 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      carouselBanner3Id
      carouselBanner3 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListSportDetailPagesQueryVariables,
  APITypes.ListSportDetailPagesQuery
 ; */ 
exports.getContentRow = /* GraphQL */ `query GetContentRow($id: ID!) {
  getContentRow(id: $id) {
    id
    title
    contentLayoutStyle
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    sportTags
    sportTagsBooleanOp
    contentIDs
    programIDs
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetContentRowQueryVariables,
  APITypes.GetContentRowQuery
 ; */ 
exports.listContentRows = /* GraphQL */ `query ListContentRows(
  $filter: ModelContentRowFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentRows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListContentRowsQueryVariables,
  APITypes.ListContentRowsQuery
 ; */ 
exports.getStreamingLandingPage = /* GraphQL */ `query GetStreamingLandingPage($id: ID!) {
  getStreamingLandingPage(id: $id) {
    id
    order
    contentRowId
    contentRow {
      id
      title
      contentLayoutStyle
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      sportTags
      sportTagsBooleanOp
      contentIDs
      programIDs
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    banner1Id
    banner1 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    banner2Id
    banner2 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    banner3Id
    banner3 {
      id
      imageId
      image {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      title
      description
      layout
      tags
      buttonUrl
      buttonText
      contentId
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    publishAtDate
    expirationAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetStreamingLandingPageQueryVariables,
  APITypes.GetStreamingLandingPageQuery
 ; */ 
exports.listStreamingLandingPages = /* GraphQL */ `query ListStreamingLandingPages(
  $filter: ModelStreamingLandingPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamingLandingPages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order
      contentRowId
      contentRow {
        id
        title
        contentLayoutStyle
        leagueId
        sport
        sportTags
        sportTagsBooleanOp
        contentIDs
        programIDs
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      banner1Id
      banner1 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      banner2Id
      banner2 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      banner3Id
      banner3 {
        id
        imageId
        title
        description
        layout
        tags
        buttonUrl
        buttonText
        contentId
        sport
        leagueId
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      publishAtDate
      expirationAtDate
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListStreamingLandingPagesQueryVariables,
  APITypes.ListStreamingLandingPagesQuery
 ; */ 
exports.getUserContentWatchList = /* GraphQL */ `query GetUserContentWatchList($id: ID!) {
  getUserContentWatchList(id: $id) {
    id
    userID
    contentID
    user {
      id
      name
      email
      createdAt
      updatedAt
      watchList {
        nextToken
        __typename
      }
      watchProgresses {
        nextToken
        __typename
      }
      notifications
      appSettingsPreferences
      stats
      __typename
    }
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      sortByDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.GetUserContentWatchListQueryVariables,
  APITypes.GetUserContentWatchListQuery
 ; */ 
exports.listUserContentWatchLists = /* GraphQL */ `query ListUserContentWatchLists(
  $filter: ModelUserContentWatchListFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserContentWatchLists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      contentID
      user {
        id
        name
        email
        createdAt
        updatedAt
        notifications
        appSettingsPreferences
        stats
        __typename
      }
      content {
        id
        title
        description
        note
        video
        gameId
        vodEntryId
        programId
        tvRating
        contentType
        applicationType
        nlseAssetHouseCode
        episode
        episodeId
        episodeOrder
        episodeSeason
        isSupplementalContent
        supplementalContentOrder
        mainContentId
        publishAtDate
        expirationAtDate
        availabilityStatus
        contentLength
        seasonYear
        seasonType
        location
        leagueId
        sport
        tags
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        reservationArn
        reservationAuthorized
        reservationName
        reservationPlaybackURL
        reservationTags
        reservationType
        oldReservationArn
        liveToVodPlaybackURL
        errorStatus
        errorMessageForVideoUpload
        sortByDate
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` ; /* GeneratedQuery<
  APITypes.ListUserContentWatchListsQueryVariables,
  APITypes.ListUserContentWatchListsQuery
 ; */ 
